import componentRegistry from '../registry.js';
import NCarousel from '../n-carousel/index.js';
import './expertPosts.less';

const RESPONSIVE_CONFIG = {
  node: [
    {
      breakpoint: 768,
      options: {
        visibles: 2,
      },
    },
    {
      breakpoint: 1200,
      options: {
        visibles: 3,
      },
    },
  ],
  fsi: [
    {
      breakpoint: 768,
      options: {
        visibles: 3,
      },
    },
    {
      breakpoint: 1200,
      options: {
        visibles: 4,
      },
    },
  ],
};

export default class ExpertPosts extends NCarousel {
  constructor({ el }) {
    const { mode } = el.dataset;
    super({
      el,
      options: {
        visibles: 1,
        responsive: [...(RESPONSIVE_CONFIG[mode] || {})],
      },
    });
  }
}

componentRegistry.define('js-ExpertPosts', ExpertPosts);
