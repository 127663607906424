import componentRegistry from '../registry.js';

export default class BestSales {
  constructor({ el }) {
    this.el = el;
    this.el.querySelector('.js-togglebestSales').addEventListener('click', this.toggle);
  }

  toggle = () => {
    this.el.classList.toggle('isClosed');
  };
}

componentRegistry.define('js-bestSales', BestSales);
