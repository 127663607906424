import componentRegistry from '../registry.js';
import Backbone from 'backbone';
import { isMobile, productListURL } from '../../configuration/Configuration.js';
import $ from 'jquery';
// import SharedDispatcher from '../../scripts/common/others/SharedDispatcher.js';
import renderCarouselDotList from './carousel-dot-list.liquid';
import DebounceEventListener from '../../helpers/event/debounce-listener.js';
import './carousel-view.less';

var carouselViewIntances = [];
var currentViewport = window.innerWidth;

var viewports = [
  ['SMALL', 992],
  ['MEDIUM', 1110],
  ['LARGE', 1281],
  ['XLARGE', 1600],
];

// Specifications defining the number of visible items per slide
// depending on the "type" of carousel
// critical, touch with full knowledge of the facts
var specifications = {
  POP: { DEFAULT: 4, XLARGE: 4, LARGE: 4, MEDIUM: 3, SMALL: 2 },
  DEFAULT: { DEFAULT: 5.5, XLARGE: 4.5, LARGE: 4.5, MEDIUM: 3.5, SMALL: 2.5 },
  XL_ITEM: { DEFAULT: 1, XLARGE: 1, LARGE: 1, MEDIUM: 1, SMALL: 1 },
  L_ITEM: { DEFAULT: 3, XLARGE: 3, LARGE: 2, MEDIUM: 2, SMALL: 1 },
  LM_ITEM: { DEFAULT: 3, XLARGE: 3, LARGE: 3, MEDIUM: 3, SMALL: 1.5 },
  M_ITEM: { DEFAULT: 3, XLARGE: 2, LARGE: 2, MEDIUM: 2, SMALL: 2 },
  SMALL_ITEM: { DEFAULT: 4, XLARGE: 4, LARGE: 4, MEDIUM: 3, SMALL: 2 },
  XS_ITEM: { DEFAULT: 6, XLARGE: 5, LARGE: 4, MEDIUM: 3, SMALL: 3 },
  TRIPLE_ITEM: { DEFAULT: 3, XLARGE: 3, LARGE: 3, MEDIUM: 3, SMALL: 3 },
  DUAL_ITEM: { DEFAULT: 2, XLARGE: 2, LARGE: 2, MEDIUM: 2, SMALL: 1 },
};

// Names of web services used to generate HTML for lazyloaded items
var PRODUCT_SERVICE = 'product-getproducts'; // use Nav/Core/Homes/Layers/ProductsLists/ProductItemList.ascx to render product

/**
 * Carousel View
 *
 * Data attributes :
 *
 * - Data-carousel-autoplay [boolean] Auto-play the carousel
 * - Data-carousel-hover-autoplay [boolean] Auto-play the carousel when the user hover the carousel once
 * - Data-carousel-dots [boolean] Displays dots
 * - Data-carousel-hover-off [boolean] Show arrows [and dots] whether it's on hover or not
 * - Data-carousel-infinite [boolean] Infinite scroll
 * - Data-carousel-type [string] Type of carousel defining how many items can be shown according to the current viewport
 *   width Possibles values: XL_ITEM, L_ITEM, M_ITEM, DEFAULT
 *
 * @property {number} autoPlayTimer ...
 * @property {boolean} hasTodisplayDots Does this carousel has to show dots ?
 * @property {boolean} endingItemsLength If the number of visible items is not a mathematical multiple of the total
 *   number of items, this property represents the number of items "left" for each extremity (first/last slide).
 * @property {boolean} hasAutoPlay Does this carousel has to auto-play ? Can be changed during execution based on
 *   hasHoverAutoPlay
 * @property {boolean} hasHoverAutoPlay Does this carousel has to auto-play when the user hover the carousel once ?
 * @property {boolean} hasHoverOff Data attribute to switch off arrows auto-hiding on mouse over (via a CSS class).
 * @property {number} index Index of the current visible slide
 * @property {boolean} hasToReset Force the the first-time rendering without the first-time DOM creations
 * @property {boolean} isLazyloaded Is this carousel lazyloaded ?
 * @property {boolean} isFirstTime Is it the first render ?
 * @property {boolean} isInfinite Is this carousel infinitely scrollable ?
 * @property {boolean} isIsometric If the number of visible items is not a mathematical multiple of the total number of
 *   items, this property will become false when the carousel will reach the last slide, because an offset equal to the
 *   number of left items will persist until the carousel reach the first slide again.
 * @property {boolean} isSliding Is this carousel currently translating (because of CSS3 transition) ?
 * @property {number} itemWidthRatio Carousel items width (in percentages)
 * @property {number} length Total number of items in the carousel
 * @property {number} loadedItems ...
 * @property {Array} lazyloadItems [Lazyload] Items ids from the data attributes
 * @property {string} specifications ...
 * @property {string} visibleItemsLength Number of visibles items per slide
 * @property {string} width Carousel width (in pixels)
 * @property {boolean} hasNativeScroll Use native scroll instead of JS-managed one. This the case on touch devices with
 *   non-single carousels.
 * @property {object} $container JQuery wrapped ...
 * @property {object} $items JQuery wrapped ...
 * @todo Remove all view.render() post-DOMContentLoaded calls to bind this view to carousels
 * @todo Refactor all anonymous functions
 * @todo Item indexing instead of slide one for notive-scroll cases (arrows)
 * @todo Track properties mergeable throw logic simplifications
 * @todo Destroy the view for
 */
export default class CarouselView extends Backbone.View {
  /** Constructor */
  initialize() {
    this.goToPreviousSlide = this.goToPreviousSlide.bind(this);
    this.goToNextSlide = this.goToNextSlide.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onScroll = this.onScroll.bind(this);
    this.onTouchEnd = this.onTouchEnd.bind(this);
    this.onTouchMove = this.onTouchMove.bind(this);
    this.onTouchStart = this.onTouchStart.bind(this);
    this.onVisible = this.onVisible.bind(this);
    this.onTransitionEnd = this.onTransitionEnd.bind(this);
    this.render = this.render.bind(this);
    this.renderItems = this.renderItems.bind(this);
    this.reset = this.reset.bind(this);
    this.startAutoPlay = this.startAutoPlay.bind(this);
    this.stopAutoPlay = this.stopAutoPlay.bind(this);
    this.autoPlayGoNextSlide = this.autoPlayGoNextSlide.bind(this);
    this.translateSlide = this.translateSlide.bind(this);

    // Force carousel to be fully instanciated even if length is lower than 0
    var force = !!this.$el.data('carouselForce');

    this.length = this.$('.js-Carousel-item').length;

    this.lazyloadService = this.$el.data('lazyloadService');
    this.isLazyloaded = this.lazyloadService != null;

    if (!force && !this.isLazyloaded && this.length < 2) {
      this.destroy();
      return;
    }

    this.hasToReset = false;
    this.index = 0;
    this.isFirstTime = true;

    this.loadedItems = this.$el.find('.js-Carousel-item:not(.js-Carousel-item-lazyload)').length;
    this.lazyloadItems = this.$el.data('lazyloadItems') || []; // each item require a placeholder

    // Behaviors
    this.hasAutoPlay = !!this.$el.data('carouselAutoplay');
    this.hasHoverAutoPlay = !!this.$el.data('carouselHoverAutoplay');
    this.hasHoverOff = !!this.$el.data('carouselHoverOff');
    this.hasNativeScroll = isMobile && this.$el.data('carouselType') !== 'XL_ITEM';
    this.hasTodisplayDots = !!this.$el.data('carouselDots');
    this.isInfinite = !!this.$el.data('carouselInfinite');

    this.reset();

    this.$container = this.$('.js-Carousel-container');

    // We store the current CarouselView instance,
    // so we can use it from outside of the View extension
    carouselViewIntances.push(this);

    this.$container.on('transitionend.Carousel', this.onTransitionEnd);

    if (this.length > 1) {
      this.$el.on('mouseenter.Carousel', this.onMouseEnter.bind(this));
      this.$el.on('mouseleave.Carousel', this.onMouseLeave.bind(this));
    }

    // Why this is not included by default in the generated HTML?
    //this.$el.addClass('Carousel--hoverOff');

    // @todo This has to go in render()
    //if (!this.$el.data("carouselType")) {
    //  this.$el.closest(".strate").addClass("Carousel--outsideNav");
    //}

    // this.listenTo(SharedDispatcher, 'carouselView:reset', this.reset);

    this.goToSlide(this.index);

    this.reset(true);
  }

  get events() {
    return {
      'click .js-Carousel-previous': 'goToPreviousSlide',
      'click .js-Carousel-next': 'goToNextSlide',
      'click .js-Carousel-goTo': 'goToSlide',
      'switch-item:visible': 'onVisible',
    };
  }

  /**
   * (Re-)set properties to their default value
   *
   * @param {boolean} hasToRender Do we need to render the view in the end ?
   */
  reset(hasToRender) {
    if (this.isDestroyed) {
      return;
    }

    this.stopAutoPlay();
    this.hasToReset = !this.isFirstTime;
    this.index = 0;
    this.isIsometric = true;
    this.isSliding = false;
    this.width = this.$('.Carousel-overflowContainer').innerWidth();

    if (this.isInfinite && !this.isFirstTime) {
      this.length = this.$('.js-Carousel-item').length - 2;
    } else {
      this.length = this.$('.js-Carousel-item').length;
    }

    // ----------------------------------------------------------------------
    // Specifications
    this.specifications = specifications[this.$el.data('carouselType')] || specifications.DEFAULT;
    this.visibleItemsLength = this.specifications[this.getCurrentViewport()];
    this.itemWidthRatio = 100 / this.visibleItemsLength;

    // DOM elements
    this.$overflowContainer = this.$('.Carousel-overflowContainer');

    // ----------------------------------------------------------------------
    // Infinite carousel
    this.isTranslating = false;
    if (this.isInfinite) {
      this.indexMax = this.length + 1;

      // ----------------------------------------------------------------------
      // Non-Infinite carousel
    } else {
      if (this.visibleItemsLength % 1 != 0) {
        this.indexMax = Math.ceil(this.length / (this.visibleItemsLength - 0.5)) - 1;
      } else {
        this.indexMax = Math.ceil(this.length / this.visibleItemsLength) - 1;
      }
      this.endingItemsLength = this.length - this.indexMax * this.visibleItemsLength;
    }

    // ----------------------------------------------------------------------
    // Touch WITHOUT Native-scroll
    if (isMobile && !this.hasNativeScroll) {
      this.isFirstTouchMove = true;
    }

    // ----------------------------------------------------------------------
    // Native-scroll
    if (this.hasNativeScroll) {
      this.isAtFirstIndex = true;
      this.isAtLastIndex = false;
      this.itemWidth = this.$('.js-Carousel-item').width();
      this.scrollRightWall = this.$overflowContainer.prop('scrollWidth') - this.width - 1;
    }

    this.$el.off('touchmove.Carousel');
    this.$el.off('touchend.Carousel touchcancel.Carousel');
    this.$el.off('touchstart.Carousel');

    if (hasToRender) {
      this.render();
    }
  }

  onMouseEnter() {
    // Will start autoplay
    if (this.hasHoverAutoPlay && !this.hasAutoPlay) {
      this.hasAutoPlay = true;
    }
    this.stopAutoPlay();
    this.hasMouseOver = true;
  }

  onMouseLeave() {
    this.hasMouseOver = false;
    this.startAutoPlay();
  }

  /**
   * Event scroll only used on touch devices (natural scroll) for non-infinite Carousels.
   *
   * @todo Check what happens with the "round" scrollLeftMax calculation
   */
  onScroll() {
    var scrollLeft = this.$overflowContainer.scrollLeft();

    if (scrollLeft > this.index * this.width || scrollLeft <= (this.index - 1) * this.width) {
      this.index = Math.ceil(scrollLeft / this.width);
    }

    // If user reach the first item of the carousel
    if (scrollLeft <= 0 && !this.isAtFirstIndex) {
      this.$('.js-Carousel-previous').prop('disabled', true);
      this.isAtFirstIndex = true;
    }

    // If user left the first item of the carousel
    if (this.isAtFirstIndex && scrollLeft > 0) {
      this.$('.js-Carousel-previous').prop('disabled', false);
      this.isAtFirstIndex = false;
    }

    // If user reach the last item of the carousel
    if (scrollLeft >= this.scrollRightWall && !this.isAtLastIndex) {
      this.$('.js-Carousel-next').prop('disabled', true);
      this.isAtLastIndex = true;
    }

    // If user left the last item of the carousel
    if (this.isAtLastIndex && scrollLeft < this.scrollRightWall) {
      this.$('.js-Carousel-next').prop('disabled', false);
      this.isAtLastIndex = false;
    }
  }

  onTouchStart(event) {
    if (this.isSliding) {
      return;
    }

    this.stopAutoPlay();

    // this.containerOriginLeft = parseInt(this.$container.css("transform").split(',')[4]);
    this.containerOriginLeft = parseInt(this.$container.css('left'));
    this.swipeOriginX = event.originalEvent.touches[0].clientX;
    this.swipeOriginY = event.originalEvent.touches[0].clientY;

    this.$el.on('touchmove.Carousel', this.onTouchMove);
    this.$el.on('touchend.Carousel touchcancel.Carousel', this.onTouchEnd);

    this.$container.addClass('Carousel-container--frozen');
  }

  onTouchMove(event) {
    var widthDistance = Math.abs(event.originalEvent.touches[0].clientX - this.swipeOriginX);

    if (this.isFirstTouchMove) {
      var heightDistance = Math.abs(event.originalEvent.touches[0].clientY - this.swipeOriginY);

      if (heightDistance > widthDistance) {
        this.onTouchEnd();
        return;
      }

      event.preventDefault();

      this.isFirstTouchMove = false;
      this.isSliding = true;
    }

    this.$container.css('left', this.containerOriginLeft + event.originalEvent.touches[0].clientX - this.swipeOriginX);
  }

  onTouchEnd(event) {
    // this.$container.css({
    //   "left": 0,
    //   "transform": "translate3d(" + this.$container.css("left") + "px, 0, 0)"
    // });

    this.isFirstTouchMove = true;

    this.$el.off('touchmove.Carousel');
    this.$el.off('touchend.Carousel touchcancel.Carousel');

    this.$container.removeClass('Carousel-container--frozen');

    if (!event) {
      this.isSliding = false;
      return;
    }

    var swipeWidth = event.originalEvent.changedTouches[0].clientX - this.swipeOriginX;

    // If the swipe was too short, or if we reached a first/last slide,
    // let's go back to the original position
    if (
      Math.abs(swipeWidth) < this.width / 20 ||
      (!this.canGoToNext() && swipeWidth < 0) ||
      (!this.canGoToPrevious() && swipeWidth > 0)
    ) {
      this.render();
      return;
    }

    if (swipeWidth < 0) {
      this.goToNextSlide();
    } else {
      this.goToPreviousSlide();
    }
  }

  onTransitionEnd() {
    if (!this.isInfinite || (this.isInfinite && !this.isTranslating)) {
      this.isSliding = false;
    }
  }

  onVisible() {
    this.render();
  }

  /** Start carousel auto-playing */
  startAutoPlay() {
    // Avoid mutiple setInterval call in case code logic went wrong
    if (!this.hasAutoPlay || this.isTranslating || this.hasMouseOver || this.autoPlayTimer !== 0) {
      return;
    }

    this.autoPlayTimer = setTimeout(this.autoPlayGoNextSlide, 4000); // call autoPlayGoNextSlide will call startAutoPlay again (indirectly)
  }

  /** Stop carousel auto-playing */
  stopAutoPlay() {
    clearTimeout(this.autoPlayTimer);

    // Reset autoPlayTimer value
    this.autoPlayTimer = 0;
  }

  /** Auto-playing go to the next slide: current slide time elasped, go to the next one and start to wait again */
  autoPlayGoNextSlide() {
    this.autoPlayTimer = 0; // don't need to clear timeout (because this function called from setTimeout())
    this.goToNextSlide();
  }

  /**
   * Go to the previous slide
   *
   * @param {object} event
   */
  goToPreviousSlide(event) {
    if ((event && this.isSliding) || (!this.hasNativeScroll && !this.isInfinite && !this.index)) {
      return;
    }
    this.stopAutoPlay(); // reset autoplay timer startAutoPlay will be called later
    this.index--;

    if (this.isInfinite) {
      // If we reach the previous group of slides
      if (this.index === -1) {
        this.isTranslating = true;
        this.isSliding = true;
        this.$container.one('transitionend', this.translateSlide);
      }
    }

    this.goToSlide(this.index);
  }

  /**
   * Go to the next slide
   *
   * @param {object} event
   */
  goToNextSlide(event) {
    if ((event && this.isSliding) || (!this.hasNativeScroll && !this.isInfinite && this.index === this.indexMax)) {
      return;
    }
    this.stopAutoPlay(); // reset autoplay timer startAutoPlay will be called later
    this.index++;
    if (this.isInfinite) {
      // If we reach the next group of slides
      if (this.index === this.length) {
        this.isTranslating = true;

        this.isSliding = true;
        this.$container.one('transitionend', this.translateSlide);
      }
    }
    this.goToSlide(this.index);
  }

  /**
   * Go to the slide n (index)
   *
   * @param {number | object} indexOrEvent Index of the slide or event > target > data-index if the user clicked on a
   *   dot
   */
  goToSlide(indexOrEvent) {
    if (isNaN(indexOrEvent)) {
      indexOrEvent.preventDefault();
      this.index = $(indexOrEvent.currentTarget).data('carouselIndex');
    } else {
      this.index = indexOrEvent;
    }

    this.render();
  }

  translateSlide() {
    this.index = this.index < 0 ? this.length - 1 : 0;

    this.$container.addClass('Carousel-container--frozen').css('left', -100 * (this.index + 1) + '%'); // less performant thant transform
    // .css("transform", "translate3d(" + (-100 * this.index) + "%, 0 , 0)");

    this.isSliding = false;
    this.isTranslating = false;

    this.startAutoPlay();
  }

  /**
   * Can we go to a previous slide ?
   *
   * @returns {boolean}
   */
  canGoToPrevious() {
    return this.isInfinite || this.index !== 0;
  }

  /**
   * Can we go to a next slide ?
   *
   * @returns {boolean}
   */
  canGoToNext() {
    return this.isInfinite || this.index !== this.indexMax;
  }

  /** Get html for each product box */
  loadItems() {
    // See render() when container's left is defined
    var count = this.lazyloadItems.length;
    var items = this.lazyloadItems.splice(0, count);
    var promise;

    if (items.length === 0) {
      return;
    }

    if (this.abortLazyload) {
      this.abortLazyload();
      this.abortLazyload = null;
    }

    switch (this.lazyloadService) {
      case PRODUCT_SERVICE: {
        // TODO replace with fetch and abort controller
        const xhr = $.ajax({
          type: 'POST',
          url: productListURL,
          contentType: 'application/json;charset=utf-8',
          data: JSON.stringify({
            pathId: this.$el.data('path-id'),
            pageName: this.$el.data('page-name'),
            localBlockName: this.$el.data('localBlockName'),
            articles: items.map((item) => {
              const [catalog, prid] = item.split('-');
              return { catalog, prid };
            }),
          }),
        });
        this.abortLazyload = xhr.abort;
        promise = xhr.then(function (data) {
          return $(data.html).filter('.js-Carousel-item').toArray();
        });
        break;
      }
      default:
        throw 'Unsupported lazy load service: ' + this.lazyloadService;
    }

    promise.done(this.renderItems);
  }

  renderItems(items) {
    this.abortLazyload = null;
    this.loadedItems += items.length;

    for (var index = 0, placeholders = this.$('.js-Carousel-item-lazyload'); index < items.length; index++) {
      var content = items[index];
      var placeholder = placeholders.eq(index).removeClass('js-Carousel-item-lazyload');
      if (typeof content === 'string' && !content.trim()) {
        // let in place the placeholder if no content
        continue;
      }
      placeholder.replaceWith(content);
    }

    // No items to load, remove extra placeholders
    if (this.lazyloadItems.length === 0) {
      this.$('.js-Carousel-item-lazyload').remove();
    }
  }

  /**
   * Returns the currently active viewport between :
   *
   * - LARGE
   * - MEDIUM
   * - SMALL or DEFAULT if larger than the `LARGE` breakpoint
   *
   * @returns {string} Current viewport name
   */
  getCurrentViewport() {
    var index = -1;

    while (++index < viewports.length) {
      if (currentViewport <= viewports[index][1]) {
        return viewports[index][0];
      }
    }

    return 'DEFAULT';
  }

  /** Render the view */
  render() {
    if (this.$el.is('.hide')) {
      return;
      // wait an event trigger after that class is changed
    }

    if (this.isFirstTime || this.hasToReset) {
      // If we are in "touch" mode
      if (isMobile) {
        // If this is a non-native scroll case, we attach the touch event
        if (!this.hasNativeScroll) {
          // Be sure the event has not already been added (remove it, then add it)
          this.$el.off('touchstart.Carousel').on('touchstart.Carousel', this.onTouchStart);
          // Else, we enable the manual scroll and follow the scroll event
        } else {
          this.$container.css('overflowX', 'auto').on('scroll', this.onScroll);
        }
      }

      if (this.isFirstTime) {
        if (this.hasHoverOff) {
          this.$el.addClass('Carousel--hoverOff');
        }

        // If infinite scroll is enabled
        if (this.isInfinite) {
          var $firstItem = this.$('.js-Carousel-item').first().clone(),
            $lastItem = this.$('.js-Carousel-item').last().clone();

          this.$container.prepend($lastItem);
          this.$container.append($firstItem);
        }

        // If there are no more slide than the visible ones,
        // we disable the previous/next buttons
        if (this.length <= this.visibleItemsLength) {
          this.$('.js-Carousel-previous, .js-Carousel-next').prop('disabled', true);
        }

        // If we have to display dots
        if (this.hasTodisplayDots) {
          const length = Math.floor(this.length / this.visibleItemsLength);
          const items = Array.from({ length }, (value, index) => ({ index })); // [{index: 0}, {index: 1}, ...]
          this.$el.append(renderCarouselDotList({ items }));
        }
      }

      this.isFirstTime = this.hasToReset = false;
    }

    // We disable buttons when we reach the first or the last index
    this.$('.js-Carousel-previous').prop('disabled', !this.canGoToPrevious());
    this.$('.js-Carousel-next').prop('disabled', !this.canGoToNext());

    // Disable buttons when we can't slide prev & next
    if (!this.canGoToPrevious() && !this.canGoToNext()) {
      this.$el.addClass('js-Carousel-disableArrow');
    } else {
      this.$el.removeClass('js-Carousel-disableArrow');
    }

    // If the transition effect was frozen because of the infinite scroll, we defroze it
    if (this.isInfinite && this.$container.hasClass('Carousel-container--frozen')) {
      this.$container.removeClass('Carousel-container--frozen');
    }

    // If dots are displayed, we update them
    if (this.hasTodisplayDots) {
      var dotIndex = this.index === this.length ? 0 : this.index;

      this.$('.js-Carousel-dotListItem--active').removeClass('js-Carousel-dotListItem--active');
      this.$('.js-Carousel-dotListItem').eq(dotIndex).addClass('js-Carousel-dotListItem--active');
    }

    let scrollLeft = 0;

    // If it's not infinite, and, that we reached the last slide or the carousel is not isometric anymore
    if (!this.isInfinite && (this.index === this.indexMax || !this.isIsometric)) {
      // If we reach the first slide, the carousel is necessarily isometric (again)
      if (!this.index) {
        this.isIsometric = true;
      } else {
        // Non-isometric calculation
        if (this.hasNativeScroll) {
          scrollLeft = this.itemWidth * (this.index * this.visibleItemsLength + this.endingItemsLength);
        } else {
          if (this.visibleItemsLength % 1 != 0) {
            // test when non isometric and not enough items for last index
            if (this.index == this.indexMax && this.$container.css('left') != '-100%') {
              this.$container.css(
                'left',
                -(this.itemWidthRatio * this.length) + this.itemWidthRatio * this.visibleItemsLength + '%'
              );
            } else {
              this.$container.css(
                'left',
                -(this.itemWidthRatio * (this.visibleItemsLength - 0.5) * this.index) +
                  this.itemWidthRatio * (this.visibleItemsLength - 1) +
                  '%'
              );
            }
          } else {
            this.$container.css('left', -100 * this.index + '%');
          }
          // this.$container.css("transform", "translate3d(" + (-this.itemWidthRatio * (((this.index - 1) * this.visibleItemsLength) + this.endingItemsLength)) + "%, 0, 0)");
        }

        // If we reach the last slide, the carousel is not isometric.
        if (this.endingItemsLength && this.index === this.indexMax) {
          this.isIsometric = false;
        }
      }
    }

    // Isometric calculation
    if (this.isIsometric) {
      // If we use the native scroll
      if (this.hasNativeScroll) {
        scrollLeft = this.index * this.width;
      } else if (this.isInfinite) {
        this.$container.css('left', -100 * (this.index + 1) + '%');
      } else {
        var ratio = this.visibleItemsLength % 1 != 0 ? this.itemWidthRatio * (this.visibleItemsLength - 0.5) : 100;
        this.$container.css('left', -ratio * this.index + '%');
      }
    }

    if (this.hasNativeScroll) {
      this.isSliding = true;

      this.$overflowContainer.animate(
        {
          scrollLeft: scrollLeft,
        },
        500,
        () => {
          this.isSliding = false;
        }
      );
    }

    this.startAutoPlay();

    if (this.isLazyloaded && this.lazyloadItems.length) {
      this.loadItems();
    }
  }

  destroy() {
    this.isDestroyed = true;
    this.$('.js-Carousel-previous, .js-Carousel-next, .js-Carousel-dotListItem').remove();
    this.undelegateEvents();
    this.$el.off('.Carousel').removeData();
    // this.remove();
    // Backbone.View.prototype.remove.call(this);
  }

  static resetInstances() {
    // Don't reset if the viewport don't change
    if (window.innerWidth == currentViewport) {
      return;
    }
    currentViewport = window.innerWidth;

    /** Reset and re-render a carousel views */
    carouselViewIntances.forEach((view) => view.reset(true));
  }
}

/** Update viewport size */
// We debounce the viewport updating to 300ms to lower calls number during resizings
$(window).on('resize.Carousel', new DebounceEventListener(CarouselView.resetInstances, 300));

componentRegistry.define('js-Carousel', CarouselView);
